<template>
  <div class="part__number-input" id="input_wrapper">
    <div class="part__number-input_minus">
      <Minus style="cursor: pointer" @click="handleMinus"/>
    </div>
    <div class="block__flex block__center block__align-bottom">
      <input @click="onHandleClick"
             :class="unselectInput ? 'part__number-input_unselectable' : ''"
             @focus="onHandleFocus"
             autocomplete="off"
             :value="value" id="i5Input"
             :style="{
               width: '100%',
               maxWidth: `${width - 110}px`,
               fontSize: `${setFontSize()}px`
             }"
      />
    </div>
    <div class="part__number-input_plus">
      <Plus tyle="cursor: pointer" @click="handlePlus" />
    </div>
  </div>
</template>

<script>
import part from '@/assets/text/part.json';
import fnc from '@/utilites/functions';

const Minus = () => import('@/assets/pictures/part/minus.svg');
const Plus = () => import('@/assets/pictures/part/plus.svg');

export default {
  name: 'InputNumber',
  data() {
    return {
      part,
      realNumber: 1, // будет содержать актуальное числовое значение
      indicatorChange: false,
      unitLength: null,
      unselectInput: false,
      fnc,
      value: '',
      elInput: null,
      selectionStart: null,
      selectionEnd: null,
      actualValue: null,
      values: {},
      cursor: null,
      selection: false,
      cursorStart: null,
      cursorEnd: null,
    };
  },
  props: {
    width: {
      type: Number,
    },
    defaultValue: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handleMinus() {
      this.unselectInput = true;
      let value = String(this.value).split(' ');
      value = value.join('');
      value = value.replace(/\D/g, '');
      if (+value >= 2) {
        this.value = String(+value - 1);
        this.onHandleChange();
      }
      setTimeout(() => {
        this.unselectInput = false;
      }, 1000);
    },
    onHandleFocus() {
      this.$nextTick(() => {
        this.elInput.setSelectionRange(this.value.length - this.unitLength,
          this.value.length - this.unitLength);
      });
    },
    handlePlus() {
      this.unselectInput = true;
      this.$nextTick(() => {
        this.elInput.setSelectionRange(this.value.length - this.unitLength,
          this.value.length - this.unitLength);
      });
      let value = String(this.value).split(' ');
      value = value.join('');
      value = value.replace(/\D/g, '');
      this.value = String(+value + 1);
      this.onHandleChange();
      setTimeout(() => {
        this.unselectInput = false;
      }, 1000);
    },
    setInputWidth() {
      let result = 20;
      const oneCounter = String(this.value).split('').filter((x) => x === '1').length;
      const otherCounter = String(this.value).length - oneCounter;
      const textWidth = (otherCounter * 32) + (oneCounter * 20);
      result = textWidth;
      if (result < 20) {
        result = 20;
      } else if (this.width && result > this.width - 110) {
        result = this.width - 110;
      }
      return result;
    },
    setFontSize() {
      let result = 50;
      const valueLength = String(this.value).length;
      switch (valueLength) {
        case 5:
          result = 45;
          break;
        case 6:
          result = 37;
          break;
        case 7:
          result = 31;
          break;
        case 9:
          result = 25;
          break;
        case 10:
          result = 23;
          break;
        case 11:
          result = 20;
          break;
        default:
          result = 20;
      }
      return result;
    },
    onChange(text) {
      let value = String(text).split(' ');
      value = value.join('');
      value = value.replace(/\D/g, '');
      this.value = fnc.getNumberWithSpaces(value);
    },
    onHandleClick() {
      if (this.elInput.selectionEnd === this.elInput.selectionStart) {
        this.selection = false;
      } else {
        this.selection = true;
      }
      this.cursor = this.value.length - this.elInput.selectionEnd;
      this.selectionStart = this.elInput.selectionStart;
      this.selectionEnd = this.elInput.selectionEnd;
      if (this.cursor < this.unitLength + 1) {
        this.$nextTick(() => {
          this.elInput.setSelectionRange(this.value.length - (this.unitLength + 1),
            this.value.length - (this.unitLength + 1));
          this.selectionStart = this.elInput.selectionStart;
          this.selectionEnd = this.elInput.selectionEnd;
          this.cursor = this.value.length - this.elInput.selectionEnd;
        });
      }
    },
    onHandlePaste(evt) {
      let pastedText = null;
      if (window.clipboardData && window.clipboardData.getData) { // IE
        pastedText = window.clipboardData.getData('Text');
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        pastedText = evt.clipboardData.getData('text/plain');
      }
      console.log(pastedText);
    },
    onHandleChange() {
      let val = this.value.split(' ');
      val = val.join('');
      val = val.replace(/\D/g, '');
      if (!val || Number(val) <= 0) {
        val = this.defaultValue;
      } else if (Number(val) >= 999999) {
        val = '999999';
      }
      this.$emit('change', val);
      this.value = String(fnc.getNumberWithSpaces(val));
      this.value += ` ${this.part.unit[this.language]}`;
      this.$nextTick(() => {
        this.elInput.setSelectionRange(this.value.length - this.cursor,
          this.value.length - this.cursor);
      });
      this.values = this.value.split('');
    },
    onHandlePress(evt) {
      let type = String(evt.code).split('');
      const fullType = String(evt.code);
      if (evt.code === 'Space') {
        evt.preventDefault();
        this.onHandleChange();
      }
      type.pop();
      type = type.join('');
      const deleteText = () => {
        evt.preventDefault();
        if (this.elInput.selectionEnd > this.elInput.selectionStart) {
          const deleteCount = this.elInput.selectionEnd - this.elInput.selectionStart;
          this.values.splice(this.elInput.selectionStart, deleteCount);
        } else if (this.elInput.selectionEnd === this.elInput.selectionStart) {
          if (this.values[this.elInput.selectionStart - 1] === ' ') {
            this.values.splice(this.elInput.selectionStart - 2, 2);
            this.$nextTick(() => {
              this.elInput.setSelectionRange(this.value.length - this.unitLength,
                this.value.length - this.unitLength);
              this.onHandleClick();
            });
          } else {
            this.values.splice(this.elInput.selectionStart - 1, 1);
            this.$nextTick(() => {
              this.elInput.setSelectionRange(this.value.length - this.unitLength,
                this.value.length - this.unitLength);
              this.onHandleClick();
            });
          }
        }
        this.value = this.values.join('');
        this.onHandleChange();
        this.$nextTick(() => {
          this.elInput.setSelectionRange(this.value.length - this.cursor,
            this.value.length - this.cursor);
        });
      };
      if (type !== 'Digit') {
        switch (fullType) {
          case 'Backspace':
            deleteText();
            break;
          case 'ArrowLeft':
            evt.preventDefault();
            this.$nextTick(() => {
              const item = this.cursor + 1;
              this.elInput.setSelectionRange(this.value.length - item,
                this.value.length - item);
              this.onHandleClick();
            });
            break;
          case 'ArrowRight':
            evt.preventDefault();
            this.$nextTick(() => {
              const item = this.cursor - 1;
              this.elInput.setSelectionRange(this.value.length - item,
                this.value.length - item);
              this.onHandleClick();
            });
            break;
          default:
            evt.preventDefault();
        }
      } else {
        evt.preventDefault();
        const deleteCount = this.elInput.selectionEnd - this.elInput.selectionStart;
        this.values.splice(this.elInput.selectionStart, deleteCount, evt.key);
        this.value = this.values.join('');
        this.onHandleChange();
      }
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    modelNumber: {
      get() {
        return this.indicatorChange ? this.realNumber : this.realNumber.toLocaleString();
      },
      set(value) {
        this.realNumber = +value.replace(/\s/g, '');
        this.$emit('input', this.realNumber);
      },
    },
  },
  mounted() {
    this.unitLength = this.part.unit[this.language].length;
    this.value = fnc.getNumberWithSpaces(String(this.defaultValue));
    this.value += ` ${this.part.unit[this.language]}`;
    this.values = String(this.value).split('');
    this.elInput = document.getElementById('i5Input');
    this.elInput.addEventListener('paste', (evt) => this.onHandlePaste(evt));
    this.elInput.addEventListener('keypress', (evt) => {
      this.onHandlePress(evt);
    });
    this.elInput.addEventListener('keydown', (evt) => {
      this.onHandlePress(evt);
    });
    this.$store.watch((state) => state.index.language, () => {
      this.onHandleChange();
      this.unitLength = this.part.unit[this.language].length;
    });
  },
  components: {
    Minus,
    Plus,
  },
};
</script>
